import { Fragment } from "react";

import UseMeetSanctionInfoForm from "./UseMeetSanctionInfoForm";

import PrimaryButton from "../../../../../../common/components/buttons/PrimaryButton";
import SecondaryButton from "../../../../../../common/components/buttons/SecondaryButton";
import DatePicker from "../../../../../../common/components/datepickers/DatePicker";
import Input from "../../../../../../common/components/inputs/Input";
import MeetClassificationTreeView from "../../../../../../common/components/tree/meetClassificationTree/MeetClassificationTreeView";
import CourseMultiSelect from "../../../../../../common/components/multiSelect/courseMultiSelect/CourseMultiSelect";
import MeetSoftwareDropdown from "../../../../../../common/components/dropdowns/meetSoftwareDropdown/MeetSoftwareDropdown";
import Dropdown from "../../../../../../common/components/dropdowns/Dropdown";
import ReadOnly from "../../../../../../common/components/readOnly/ReadOnly";
import PopUpModal from "../../../../../../common/components/dialogs/PopUpModal";
import Headings from "../../../../../../common/components/headings/Headings";

import global from '../../../../../../common/components/GlobalStyle.module.css';
import Constants from "../../../../../../common/utils/Constants";

const MeetSanctionInfoForm = ({ savedFormState, clubFacilities, orgUnitId, onSubmitFormCallback, onCancelClicked, state, setState }) => {
  const {
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged,
    onValueTextPairChanged,
    onUploadTemplateClicked,
    removeFileModalState,
    onOpenRemoveFileModalClicked,
    onRemoveTemplateClicked,
    onRemoveFileModalCanceled,
    meetFileUploadState,
    onDownloadUploadedFile
  } = UseMeetSanctionInfoForm(savedFormState, onSubmitFormCallback, state, setState);

  return (
    <Fragment>
      <form onSubmit={handleSubmit} noValidate>
        <div className="row usas-extra-bottom-margin">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <Headings.H6>Meet Announcement (Optional)</Headings.H6>
          </div>
          <div className="col-xs-12">
            {formState.meetAnnouncementUrl === '' || formState.meetAnnouncementFileName.includes("Bad Request") ?
              <Fragment>
                <label htmlFor="announcement-file-upload" className={global.FileUpload}>
                  Upload File
                </label>
                <input hidden id="announcement-file-upload" type="file" onChange={onUploadTemplateClicked} />
                &nbsp;(*.pdf), (*.doc), or (*.docx)
              </Fragment>
              :
              <Fragment>
                <SecondaryButton onClick={onOpenRemoveFileModalClicked}>Remove Uploaded File</SecondaryButton>&nbsp;
                <PrimaryButton type="button" onClick={(e) => {onDownloadUploadedFile(e)}}>Download Uploaded File</PrimaryButton>
              </Fragment>}
          </div>
          {formState.meetAnnouncementFileName.includes("Bad Request") === false && formState.meetAnnouncementFileName !== '' &&
            <div className="usas-extra-bottom-margin">
              <div className="col-xs-12">
                <p><b>Uploaded File Name:</b> {formState.meetAnnouncementFileName}</p>
              </div>
            </div>
          }
          {formState.meetAnnouncementFileName.includes("Bad Request") === true &&
            <div className="col-xs-12">
              <p className={global.ErrorMessage} style={{ fontSize: 16, fontWeight: 'bold' }}>Invalid File Type</p>
            </div>
          }
          {state.error &&
            <div className="col-xs-12">
              <p className={global.ErrorMessage}>{state?.error ? state?.error : ''}</p>
            </div>
          }
          <div className="col-xs-12 usas-extra-top-margin"></div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="Year for Meet Name"
              name="autogeneratedMeetYear"
              value={formState.autogeneratedMeetYear}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <ReadOnly
              label="LSC for Meet Name"
              name="autogeneratedMeetLsc"
              value={formState.autogeneratedMeetLsc}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Input
              label="Meet Name* (Do not include Year or LSC)"
              name="meetName"
              value={formState.meetName}
              error={errorState.meetName}
              message={errorState.meetName}
              onChange={(value) => { onFormValueChanged('meetName', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker label="Start Date*"
              name="startDate"
              value={formState.startDate}
              error={errorState.startDate}
              message={errorState.startDate}
              onChange={(value) => {
                onFormValueChanged('startDate', value);
                onFormValueChanged('autogeneratedMeetYear', value.toString().substring(6, 10) !== '0000' ? value.toString().substring(6, 10) : '')
              }}
              countOfYears={5}
              startYearOffset={0} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <DatePicker label="End Date*"
              name="endDate"
              value={formState.endDate}
              error={errorState.endDate}
              message={errorState.endDate}
              onChange={(value) => { onFormValueChanged('endDate', value); }}
              countOfYears={5}
              startYearOffset={0} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <MeetClassificationTreeView
              label="Meet Classification*"
              name="meetClassification"
              value={formState.meetClassification}
              error={errorState.meetClassification}
              message={errorState.meetClassification}
              selectableLeavesOnly={true}
              singleSelect={true}
              onChange={(newValue) => { onFormValueChanged('meetClassification', newValue); }}
              orgUnitId={orgUnitId || ''}
              filterId={-1}
              filterMode={"exclude_filter_id"}
            />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <Dropdown
              label="Facility*"
              name="facilityId"
              value={formState.facilityId}
              error={errorState.facilityId}
              message={errorState.facilityId}
              options={clubFacilities}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'facilityId', newValueLabel, 'facilityName', e); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <CourseMultiSelect
              label="Meet Course(s)*"
              name="meetCourse"
              value={formState.meetCourse}
              error={errorState.meetCourse}
              message={errorState.meetCourse}
              onChange={(value) => { onFormValueChanged('meetCourse', value); }} />
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4">
            <MeetSoftwareDropdown
              label="Meet Management Software*"
              name="meetSoftwareId"
              value={formState.meetSoftwareId}
              error={errorState.meetSoftwareId}
              message={errorState.meetSoftwareId}
              onChange={(newValue, newValueLabel, e) => { onValueTextPairChanged(newValue, 'meetSoftwareId', newValueLabel, 'meetSoftwareName', e); }} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin usas-extra-bottom-margin">
            <PrimaryButton type="submit">Continue</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onCancelClicked}>Back</SecondaryButton>
          </div>
        </div>
      </form>
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.SAVING_MSG}
        displayPopUp={meetFileUploadState.isObjLoading} />
      <PopUpModal
        widthPct={90}
        maxWidth={720}
        title={removeFileModalState.modalTitle}
        displayPopUp={removeFileModalState.displayPopUp}
        onModalCanceled={onRemoveFileModalCanceled}>
        <div className="row">
          <div className="col-xs-12">
            <p><b>File Type:</b> Meet Announcement</p>
            <p><b>Uploaded File Name:</b> {removeFileModalState.fileName}</p>
          </div>
        </div>
        <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <PrimaryButton type="button" onClick={onRemoveTemplateClicked}>Yes, Remove</PrimaryButton>&nbsp;
            <SecondaryButton type="button" onClick={onRemoveFileModalCanceled}>Cancel</SecondaryButton>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default MeetSanctionInfoForm;