import { Fragment } from 'react';
import { Routes, Route } from 'react-router-dom';
import { Outlet } from '../common/wrappers/ReactRouterDom';

import useNavRoutes from '../common/state/security/UseNavRoutes';

import Club from './views/club/Club';
import ClubRegistration from './views/clubRegistration/ClubRegistration';
import PreComp from './views/preComp/PreComp';
import MemberAdministration from './views/memberAdministration/MemberAdministration';
import PreCompRegistration from './views/preCompRegistration/PreCompRegistration';
import ClubRecognition from './views/clubRecognition/ClubRecognition';
import SafeSport from './views/safeSport/SafeSport';
import ClubSwimAThon from './views/clubSwimAThon/ClubSwimAThon';
import ClubRenewal from './views/clubRenewal/ClubRenewal';
import LearnToSwimRegistration from './views/learnToSwimRegistration/LearnToSwimRegistration';
import LearnToSwim from './views/learnToSwim/LearnToSwim';
import LearnToSwimVoucher from './views/learnToSwim/learnToSwimVoucher/LearnToSwimVoucher';
import LearnToSwimBulkImport from './views/learnToSwim/learnToSwimBulkImport/LearnToSwimBulkImport';
import ClubPerformance from './views/clubPerformance/ClubPerformance';
import ZoneReporting from './views/zoneReporting/ZoneReporting';
import MemberInsuranceInformation from './views/memberInsuranceInformation/MemberInsuranceInformation';

import LoadingModal from '../common/components/dialogs/LoadingModal';
import SecuredRouteElement from '../common/components/security/SecuredRouteElement';
import PageNotFound from '../common/components/PageNotFound';
import UnexpectedError from '../common/components/UnexpectedError';
import ErrorBoundary from '../common/components/errors/ErrorBoundary';
import ErrorBoundaryFallback from '../common/components/errors/ErrorBoundaryFallback';

const AppRoutes = () => {
  const { navRoutes, isReadyToRoute } = useNavRoutes();

  if (isReadyToRoute !== true) {
    return <LoadingModal />;
  }

  return (
    <ErrorBoundary fallback={<ErrorBoundaryFallback />}>
      <Routes>
        <Route path={navRoutes['HOME']?.path} element={<Fragment><Outlet /></Fragment>}>
          <Route path={navRoutes['CLUB_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['CLUB_ROOT']}
                editElement={<Club />} />
            } />
          <Route path={navRoutes['CLUB_REGISTRATION_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['CLUB_REGISTRATION_ROOT']}
                editElement={<ClubRegistration />} />
            } />
          <Route path={navRoutes['PRE_COMP_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['PRE_COMP_ROOT']}
                editElement={<PreComp />} />
            } />
          <Route path={navRoutes['PRE_COMP_REGISTRATION_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['PRE_COMP_REGISTRATION_ROOT']}
                editElement={<PreCompRegistration />} />
            } />
          <Route path={navRoutes['CLUB_MEMBER_ADMIN_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['CLUB_MEMBER_ADMIN_ROOT']}
                editElement={<MemberAdministration />} />
            } />
          <Route path={navRoutes['CLUB_RECOGNITION_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['CLUB_RECOGNITION_ROOT']}
                editElement={<ClubRecognition />} />
            } />
          <Route path={navRoutes['SAFE_SPORT_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['SAFE_SPORT_ROOT']}
                editElement={<SafeSport />} />
            } />
          <Route path={navRoutes['SWIM_A_THON']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['SWIM_A_THON']}
                editElement={<ClubSwimAThon />} />
            } />
          <Route path={navRoutes['CLUB_RENEWAL_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['CLUB_RENEWAL_ROOT']}
                editElement={<ClubRenewal />} />
            } />
          <Route path={navRoutes['LEARN_TO_SWIM_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['LEARN_TO_SWIM_ROOT']}
                editElement={<LearnToSwim />} />
            } />
          <Route path={navRoutes['LEARN_TO_SWIM_REGISTRATION_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['LEARN_TO_SWIM_REGISTRATION_ROOT']}
                editElement={<LearnToSwimRegistration />} />
            } />
          <Route path={navRoutes['LEARN_TO_SWIM_VOUCHER']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['LEARN_TO_SWIM_VOUCHER']}
                editElement={<LearnToSwimVoucher />} />
            } />
          <Route path={navRoutes['LEARN_TO_SWIM_BULK_IMPORT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['LEARN_TO_SWIM_BULK_IMPORT']}
                editElement={<LearnToSwimBulkImport />} />
            } />
          {<Route path={navRoutes['CLUB_PERFORMANCE_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['CLUB_PERFORMANCE_ROOT']}
                editElement={<ClubPerformance />} />
            } />}
          {<Route path={navRoutes['ZONE_REPORTING_ROOT']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['ZONE_REPORTING_ROOT']}
                editElement={<ZoneReporting />} />
            } />}
          <Route path={navRoutes['MEMBER_INSURANCE_INFORMATION']?.path}
            element={
              <SecuredRouteElement
                navRoute={navRoutes['MEMBER_INSURANCE_INFORMATION']}
                editElement={<MemberInsuranceInformation />} />
            } />

          <Route path={'500'} element={<UnexpectedError />} />
          <Route path={'/*'} element={<PageNotFound />} />
        </Route>
      </Routes>
    </ErrorBoundary>
  );
};

export default AppRoutes;