import { useEffect } from 'react';

import usePaymentsData from '../../../../state/payments/UsePaymentsData';
import usePaymentsShoppingCartData from '../../../../state/payments/paymentsShoppingCart/UsePaymentsShoppingCartData';
import useShoppingCartLoadingModalData from '../../../../state/payments/paymentsShoppingCart/shoppingCartLoadingModal/UseShoppingCartLoadingModalData';

const usePaymentComplete = () => {
  const { paymentReceiptState, getPaymentReceipt, setCartChanged } = usePaymentsData();
  const { setShoppingCartError } = useShoppingCartLoadingModalData();
  const { paymentsShoppingCartState, resetPaymentsShoppingCartState } = usePaymentsShoppingCartData();

  const onBackToCartClicked = () => {
    resetPaymentsShoppingCartState();
  };

  const onDownloadReceiptClicked = () => {
    showReceiptFile(paymentsShoppingCartState.checkoutPageData?.stripeReceiptNumber)
  };

  async function showReceiptFile(paymentReceiptNumber) {
    if (paymentReceiptNumber) {
      const getPaymentReceiptPromise = getPaymentReceipt(paymentReceiptNumber);

      if (getPaymentReceiptPromise ?? false) {
        getPaymentReceiptPromise.then((newState) => {
          if (newState ?? false) {
            var file = window.URL.createObjectURL(newState.objData?.blobFile);
            const link = document.createElement('a');

            link.href = file;
            link.setAttribute('target', '_blank');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
          }
        }).catch(() => {
          setShoppingCartError(true, 'Receipt Error', 'An error occurred while trying to retrieve your receipt.');
        });
      }
    }
  }

  useEffect(() => {
    setCartChanged(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: paymentReceiptState.isObjLoading,
    hasReceipt: paymentsShoppingCartState.checkoutPageData?.stripeReceiptNumber ? true : false,
    isPending: paymentsShoppingCartState.checkoutPageData?.paymentPending === true,
    swimmingWorldOptIn: paymentsShoppingCartState.checkoutPageData?.swimmingWorldOptIn,
    onBackToCartClicked,
    onDownloadReceiptClicked
  };
};

export default usePaymentComplete;