import { useState } from 'react';

import RecFileData from './RecFileData';

const useRecFilesData = () => {
  const [recFileUploadState, setRecFileUploadState] = useState(RecFileData.REC_FILE_UPLOAD_INITIAL_STATE);
  const [recFileDownloadState, setRecFileDownloadState] = useState(RecFileData.REC_FILE_DOWNLOAD_INITIAL_STATE);

  const postRecFile = (file) => {
    RecFileData.postRecFileData(file, recFileUploadState, setRecFileUploadState);
  };

  const getRecFile = (fileUrl) => {
    return RecFileData.getRecFileData(fileUrl, recFileDownloadState, setRecFileDownloadState);
  };

  const getFileNameFromSavedUploadUrl = () => {
    if (recFileUploadState.isObjLoaded === true) {
      const fileNameWithDateTime = recFileUploadState.objData.uploadUrl.replace(RecFileData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return recFileUploadState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    try {
      const fileNameWithDateTime = url.replace(RecFileData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return decodeURI(fileName) || '';
    }
    catch (e) {
      return url;
    }
  };

  return {
    recFileUploadState,
    recFileDownloadState,
    postRecFile,
    getRecFile,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl
  };
};

export default useRecFilesData;