import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const postUsasClubFileData = (file, state, setState) => {
  if (file) {
    const formData = new FormData();
    formData.append("name", file?.name || '');
    formData.append("file", file);

    const api = initApi(API_NAMES.CLUB, state, setState);
    const url = `/UsasClubFiles`;
    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const getUsasClubFileData = (fileUrl, state, setState) => {
  if (fileUrl) {
    const api = initApi(API_NAMES.CLUB, state, setState);
    const url = `/UsasClubFiles?fileUrl=${fileUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const getUsasClubFileTemplateData = (type, state, setState) => {
  if (type) {
    const api = initApi(API_NAMES.CLUB, state, setState);
    const url = `/UsasClubFiles/downloadTemplate/${type}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const BASE_UPLOAD_URL = import.meta.env.VITE_CLUB_DOCS_UPLOAD_CONTAINER_BASE_UPLOAD_URL;

const USAS_CLUB_FILE_UPLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const USAS_CLUB_FILE_DOWNLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const USAS_CLUB_FILE_TEMPLATE_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const UsasClubFileData = {
  BASE_UPLOAD_URL,
  USAS_CLUB_FILE_UPLOAD_INITIAL_STATE,
  USAS_CLUB_FILE_DOWNLOAD_INITIAL_STATE,
  USAS_CLUB_FILE_TEMPLATE_INITIAL_STATE,
  postUsasClubFileData,
  getUsasClubFileData,
  getUsasClubFileTemplateData
};

export default UsasClubFileData;