import { useState } from 'react';

import PreCompFilesFormW9Data from './PreCompFilesFormW9Data';

//Note: If this file is ever used again, it should be refactored according to task #6097

const usePreCompFilesFormW9Data = () => {
  const [preCompFilesFormW9State, setPreCompFilesFormW9State] = useState(PreCompFilesFormW9Data.INITIAL_STATE);

  const postPreCompFilesFormW9 = (file) => {
    PreCompFilesFormW9Data.postPreCompFilesFormW9Data(file, preCompFilesFormW9State, setPreCompFilesFormW9State);
  };

  const resetPreCompFilesFormW9State = () => {
    setPreCompFilesFormW9State(PreCompFilesFormW9Data.INITIAL_STATE);
  };

  const getFileNameFromSavedUploadUrl = () => {
    if (preCompFilesFormW9State.isObjLoaded === true) {
      const fileNameWithDateTime = preCompFilesFormW9State.objData.uploadUrl.replace(PreCompFilesFormW9Data.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return preCompFilesFormW9State.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    const fileNameWithDateTime = url.replace(PreCompFilesFormW9Data.BASE_UPLOAD_URL, '');
    const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
    const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
    return decodeURI(fileName) || '';
  };

  return {
    preCompFilesFormW9State,
    postPreCompFilesFormW9,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl,
    resetPreCompFilesFormW9State,
    BASE_UPLOAD_URL: PreCompFilesFormW9Data.BASE_UPLOAD_URL,
    GET_DOWNLOAD_TEMPLATE_PATH: PreCompFilesFormW9Data.GET_DOWNLOAD_TEMPLATE_PATH,
    GET_PRE_COMP_FILES_FORM_W9_PATH: PreCompFilesFormW9Data.GET_PRE_COMP_FILES_FORM_W9_PATH
  };
};

export default usePreCompFilesFormW9Data;