import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const postLTSCurriculumFileData = (file, state, setState) => {
  if (file) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/LearnToSwimFiles`;
    const formData = new FormData();

    formData.append("name", file?.name || '');
    formData.append("file", file);

    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const postLTSFormW9FileData = (file, state, setState) => {
  if (file) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/LearnToSwimFiles`;
    const formData = new FormData();

    formData.append("name", file?.name || '');
    formData.append("file", file);

    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const postLTSLogoFileData = (file, state, setState) => {
  if (file) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/LearnToSwimFiles`;
    const formData = new FormData();

    formData.append("name", file?.name || '');
    formData.append("file", file);

    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};
const postLTSProofOfInsuranceFileData = (file, state, setState) => {
  if (file) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/LearnToSwimFiles`;
    const formData = new FormData();

    formData.append("name", file?.name || '');
    formData.append("file", file);

    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const getLTSFileData = (fileUrl, state, setState) => {
  if (fileUrl) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/LearnToSwimFiles?fileUrl=${fileUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const getLearnToSwimFileTemplateData = (type, state, setState) => {
  if (type) {
    const api = initApi(API_NAMES.COMMON, state, setState);
    const url = `/LearnToSwimFiles/downloadTemplate/${type}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const BASE_UPLOAD_URL = import.meta.env.VITE_LTS_DOCS_UPLOAD_FILES_BASE_UPLOAD_URL;

const LEARN_TO_SWIM_CURRICULUM_FILE_UPLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const LEARN_TO_SWIM_FORM_W9_FILE_UPLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const LEARN_TO_SWIM_LOGO_FILE_UPLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const LEARN_TO_SWIM_PROOF_OF_INSURANCE_FILE_UPLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const LEARN_TO_SWIM_FILE_DOWNLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const LEARN_TO_SWIM_FILE_TEMPLATE_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const LearnToSwimFileData = {
  BASE_UPLOAD_URL,
  LEARN_TO_SWIM_CURRICULUM_FILE_UPLOAD_INITIAL_STATE,
  LEARN_TO_SWIM_FORM_W9_FILE_UPLOAD_INITIAL_STATE,
  LEARN_TO_SWIM_LOGO_FILE_UPLOAD_INITIAL_STATE,
  LEARN_TO_SWIM_PROOF_OF_INSURANCE_FILE_UPLOAD_INITIAL_STATE,
  LEARN_TO_SWIM_FILE_DOWNLOAD_INITIAL_STATE,
  LEARN_TO_SWIM_FILE_TEMPLATE_INITIAL_STATE,
  postLTSCurriculumFileData,
  postLTSFormW9FileData,
  postLTSLogoFileData,
  postLTSProofOfInsuranceFileData,
  getLTSFileData,
  getLearnToSwimFileTemplateData,
};

export default LearnToSwimFileData;