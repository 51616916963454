import { useState } from 'react';

import LearnToSwimFileData from './LearnToSwimFileData';

const useLearnToSwimFileData = () => {
  const [ltsCurriculumFileUploadState, setLTSCurriculumFileUploadState] = useState(LearnToSwimFileData.LEARN_TO_SWIM_CURRICULUM_FILE_UPLOAD_INITIAL_STATE);
  const [ltsFormW9FileUploadState, setLTSFormW9FileUploadState] = useState(LearnToSwimFileData.LEARN_TO_SWIM_FORM_W9_FILE_UPLOAD_INITIAL_STATE);
  const [ltsLogoFileUploadState, setLTSLogoFileUploadState] = useState(LearnToSwimFileData.LEARN_TO_SWIM_LOGO_FILE_UPLOAD_INITIAL_STATE);
  const [ltsProofOfInsuranceFileUploadState, setLTSProofOfInsuranceFileUploadState] = useState(LearnToSwimFileData.LEARN_TO_SWIM_PROOF_OF_INSURANCE_FILE_UPLOAD_INITIAL_STATE);
  const [usasClubFileDownloadState, setUsasClubFileDownloadState] = useState(LearnToSwimFileData.LEARN_TO_SWIM_FILE_DOWNLOAD_INITIAL_STATE);
  const [learnToSwimFileTemplateState, setLearnToSwimFileTemplateState] = useState(LearnToSwimFileData.LEARN_TO_SWIM_FILE_TEMPLATE_INITIAL_STATE);

  const postLTSCurriculumFile = (curriculumFile) => {
    return LearnToSwimFileData.postLTSCurriculumFileData(curriculumFile, ltsCurriculumFileUploadState, setLTSCurriculumFileUploadState);
  };

  const postLTSFormW9File = (formW9File) => {
    return LearnToSwimFileData.postLTSFormW9FileData(formW9File, ltsFormW9FileUploadState, setLTSFormW9FileUploadState);
  };

  const postLTSLogoFile = (logoFile) => {
    return LearnToSwimFileData.postLTSLogoFileData(logoFile, ltsLogoFileUploadState, setLTSLogoFileUploadState);
  };

  const postLTSProofOfInsuranceFile = (proofOfInsuranceFile) => {
    return LearnToSwimFileData.postLTSProofOfInsuranceFileData(proofOfInsuranceFile, ltsProofOfInsuranceFileUploadState, setLTSProofOfInsuranceFileUploadState);
  };

  const getLTSFile = (fileUrl) => {
    return LearnToSwimFileData.getLTSFileData(fileUrl, usasClubFileDownloadState, setUsasClubFileDownloadState);
  };

  const getLearnToSwimFileTemplate = (type) => {
    return LearnToSwimFileData.getLearnToSwimFileTemplateData(type, learnToSwimFileTemplateState, setLearnToSwimFileTemplateState);
  };

  return {
    ltsCurriculumFileUploadState,
    ltsFormW9FileUploadState,
    ltsLogoFileUploadState,
    ltsProofOfInsuranceFileUploadState,
    learnToSwimFileTemplateState,
    postLTSCurriculumFile,
    postLTSFormW9File,
    postLTSLogoFile,
    postLTSProofOfInsuranceFile,
    getLTSFile,
    getLearnToSwimFileTemplate
  };
};

export default useLearnToSwimFileData;