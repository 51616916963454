import { useEffect, useState } from 'react';

import validate from './RegistrationSubmitFormValidation';

import useLearnToSwimFileData from '../../../../state/learnToSwimFile/UseLearnToSwimFileData';

import UseForm from "../../../../../common/utils/UseForm";
import Constants from '../../../../../common/utils/Constants';

const INITIAL_FORM_STATE = {
  logoUrl: '',
  logoFile: {},
  curriculumUrl: '',
  curriculumFile: {},
  proofOfInsuranceUrl: '',
  proofOfInsuranceFile: {},
  formW9Url: '',
  formW9File: {}
};

const useRegistrationSubmitForm = (onSubmitFormCallback) => {
  const { ltsLogoFileUploadState, postLTSLogoFile, ltsCurriculumFileUploadState, postLTSCurriculumFile,
    ltsProofOfInsuranceFileUploadState, postLTSProofOfInsuranceFile, ltsFormW9FileUploadState, postLTSFormW9File
  } = useLearnToSwimFileData();
  const [state, setState] = useState({ trySubmit: false });
  const { formState, errorState, isDirty, setIsDirty, setFormState, handleSubmit, updateFormState,
    setErrors
  } = UseForm(INITIAL_FORM_STATE, localSubmitFormCallback, validate);

  const onLogoFileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, logoFile: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_JPG) {
        postLTSLogoFile(selectedFile);
        updateFormState('logoFile', selectedFile);
      } else {
        setErrors({ ...errorState, logoFile: 'File type not permitted. Please provide a (*.jpg) file.' });
      }
    }
  };

  const onLogoFileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      logoFile: {},
      logoUrl: ''
    });
  };

  const onCurriculumFileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, curriculumFile: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
        fileType === Constants.FILE_TYPE_DOCX) {
        postLTSCurriculumFile(selectedFile);
        updateFormState('curriculumFile', selectedFile);
      } else {
        setErrors({ ...errorState, curriculumFile: 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.' });
      }
    }
  };

  const onCurriculumFileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      curriculumFile: {},
      curriculumUrl: ''
    });
  };

  const onProofOfInsuranceFileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, proofOfInsuranceFile: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
        fileType === Constants.FILE_TYPE_DOCX) {
        postLTSProofOfInsuranceFile(selectedFile);
        updateFormState('proofOfInsuranceFile', selectedFile);
      } else {
        setErrors({ ...errorState, proofOfInsuranceFile: 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.' });
      }
    }
  };

  const onProofOfInsuranceFileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      proofOfInsuranceFile: {},
      proofOfInsuranceUrl: ''
    });
  };

  const onFormW9FileLoad = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setErrors({ ...errorState, formW9File: '' });
    if (e.target.files[0]) {
      const selectedFile = e.target.files[0];
      const fileType = e.target.files[0].type;

      if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
        fileType === Constants.FILE_TYPE_DOCX) {
        postLTSFormW9File(selectedFile);
        updateFormState('formW9File', selectedFile);
      } else {
        setErrors({ ...errorState, formW9File: 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.' });
      }
    }
  };

  const onFormW9FileUnload = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setFormState({
      ...formState,
      formW9File: {},
      formW9Url: ''
    });
  };

  useEffect(() => {
    if (isDirty !== true) {
      setIsDirty(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    if (state.trySubmit === true && ltsLogoFileUploadState.isObjLoading === false &&
      ltsCurriculumFileUploadState.isObjLoaded === true && ltsCurriculumFileUploadState.objData.uploadUrl &&
      ltsProofOfInsuranceFileUploadState.isObjLoaded === true && ltsProofOfInsuranceFileUploadState.objData.uploadUrl &&
      ltsFormW9FileUploadState.isObjLoaded === true && ltsFormW9FileUploadState.objData.uploadUrl) {
      onSubmitFormCallback({
        logoUrl: ltsLogoFileUploadState.objData.uploadUrl || null,
        curriculumUrl: ltsCurriculumFileUploadState.objData.uploadUrl || null,
        proofOfInsuranceUrl: ltsProofOfInsuranceFileUploadState.objData.uploadUrl || null,
        formW9Url: ltsFormW9FileUploadState.objData.uploadUrl || null
      });
      setState({ ...state, trySubmit: false });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, ltsLogoFileUploadState, ltsCurriculumFileUploadState, ltsProofOfInsuranceFileUploadState, ltsFormW9FileUploadState])

  function localSubmitFormCallback(formState) {
    if (!formState.curriculumFile?.size || !formState.proofOfInsuranceFile?.size || !formState.formW9File?.size) {
      setErrors({
        ...errorState,
        curriculumFile: formState.curriculumFile?.size ? '' : 'A file upload is required',
        proofOfInsuranceFile: formState.proofOfInsuranceFile?.size ? '' : 'A file upload is required',
        formW9File: formState.formW9File?.size ? '' : 'A file upload is required'
      });
    }
    else {
      setState({ ...state, trySubmit: true });
    }
  }

  return {
    isSaving: ltsLogoFileUploadState.isObjLoading || ltsCurriculumFileUploadState.isObjLoading ||
      ltsProofOfInsuranceFileUploadState.isObjLoading || ltsFormW9FileUploadState.isObjLoading,
    formState,
    errorState,
    handleSubmit,
    onLogoFileLoad,
    onLogoFileUnload,
    onCurriculumFileLoad,
    onCurriculumFileUnload,
    onProofOfInsuranceFileLoad,
    onProofOfInsuranceFileUnload,
    onFormW9FileLoad,
    onFormW9FileUnload
  };
};

export default useRegistrationSubmitForm;