import { useState } from "react";
import { navigateToCrossUiRoute } from '../../../../common/utils/FormatCrossUiRoute';

import useLearnToSwimBulkImportUploadData from "../../../state/learnToSwimBulkImportUpload/UseLearnToSwimBulkImportUploadData";
import useLearnToSwimFileData from "../../../state/learnToSwimFile/UseLearnToSwimFileData";

import useGlobalRoutesData from "../../../../common/state/security/UseGlobalRoutesData";

import Constants from "../../../../common/utils/Constants";

import { useNavigate } from "../../../../common/wrappers/ReactRouterDom";
import { useEffect } from "react";

const INITIAL_MODAL_STATE = {
  displayPopUp: false,
  modalTitle: 'Learn To Swim Bulk Import - Upload Confirmation'
};

const useLearnToSwimBulkImport = () => {
  const navigate = useNavigate();
  const { learnToSwimBulkImportUploadState, postProcessLearnToSwimBulkImportIds,
    resetLearnToSwimBulkImportUploadState, resetState } = useLearnToSwimBulkImportUploadData();
  const { getLearnToSwimFileTemplate } = useLearnToSwimFileData();
  const [fileState, setFileState] = useState({});
  const [learnToSwimProvidersArrayState, setMemberIdArrayState] = useState([]);
  const [fileErrorState, setFileErrorState] = useState('');
  const [modalState, setModalState] = useState(INITIAL_MODAL_STATE);
  const { getGlobalRoute } = useGlobalRoutesData();

  const onLoadFile = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    const file = e.target.files[0];
    if (file) {
      resetState();

      if (file.type === Constants.FILE_TYPE_CSV) {
        readCsvFile(file);
      } else {
        setFileErrorState(`Invalid file type`);
      }
    } else {
      setFileErrorState('No file loaded');
    }
  };

  const onDownloadJSON = () => {
    const json = JSON.stringify(learnToSwimProvidersArrayState);
    const blob = new Blob([json], { type: 'application/json' });
    const href = URL.createObjectURL(blob);
    const link = document.createElement('a');

    link.href = href;
    link.download = 'LearnToSwimProviders.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onUploadLearnToSwimProviders = () => {
    postProcessLearnToSwimBulkImportIds(learnToSwimProvidersArrayState);
  };

  const onModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setModalState(INITIAL_MODAL_STATE);
  };

  const onContinueClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    onModalCanceled();
    //Navigate to cart
    const paymentsRoute = getGlobalRoute('PAYMENTS_SHOPPING_CART');
    if (paymentsRoute && paymentsRoute.route) {
      navigateToCrossUiRoute(paymentsRoute.uIProjectName, paymentsRoute.route, navigate);
    }
  };

  const onDownloadTemplateClicked = (e, type) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    showUploadedFileTemplate(type)
  };

  useEffect(() => {
    if (learnToSwimBulkImportUploadState.isSaved === true &&
      learnToSwimBulkImportUploadState.isObjLoading === false &&
      learnToSwimBulkImportUploadState.isSaving === false) {
      setModalState({ ...modalState, displayPopUp: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [learnToSwimBulkImportUploadState])

  function readCsvFile(file) {
    const fileReader = new FileReader();

    fileReader.onload = function (event) {
      // Read file data
      var csvdata = event.target.result;

      // Split by line break to gets rows Array
      var rowData = csvdata.split('\n');
      parseFileResult(rowData);
    };

    fileReader.readAsText(file);

    setFileState(file);
    setFileErrorState('');
  }

  function parseFileResult(rowData) {
    const result = [];
    // Loop on the row Array (change row=0 if you also want to read 1st header row)
    for (var row = 1; row < rowData.length - 1; row++) {
      // Split by comma (,) to get column Array
      let rowColData = rowData[row].split(',');
      result.push({
        entityName: rowColData[0]?.trim() || null,
        tradeName: rowColData[1]?.trim() || '',
        website: rowColData[2]?.trim() || '',
        taxId: rowColData[3]?.trim() || '',
        learnToSwimCurriculumId: rowColData[4]?.trim() || '',
        facilityName: rowColData[5]?.trim() || '',
        facilityAddress1: rowColData[6]?.trim() || '',
        facilityAddress2: rowColData[7]?.trim() || '',
        facilityCity: rowColData[8]?.trim() || '',
        facilityStateCode: rowColData[9]?.trim() || '',
        facilityPostalCode: rowColData[10]?.trim() || '',
        facilityContactName: rowColData[11]?.trim() || '',
        facilityContactEmailAddress: rowColData[12]?.trim() || '',
        facilityContactPhoneNumber: rowColData[13]?.trim() || ''
      });
    }

    setMemberIdArrayState(result);
  }

  async function showUploadedFileTemplate(type) {
    if (type) {
      const getFileResult = await getLearnToSwimFileTemplate(type);

      if (getFileResult) {
        if (getFileResult.objData?.blobFile?.size > 0) {
          var file = window.URL.createObjectURL(getFileResult.objData?.blobFile);
          const link = document.createElement('a');

          link.href = file;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    }
  }

  return {
    isLoading: learnToSwimBulkImportUploadState.isObjLoading,
    isSaving: learnToSwimBulkImportUploadState?.isSaving,
    learnToSwimBulkImportUploadState,
    learnToSwimProvidersArrayState,
    fileState,
    fileErrorState,
    onLoadFile,
    onDownloadJSON,
    onUploadLearnToSwimProviders,
    modalState,
    onModalCanceled,
    onContinueClicked,
    resetLearnToSwimBulkImportUploadState,
    onDownloadTemplateClicked
  };
};

export default useLearnToSwimBulkImport;