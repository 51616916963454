import { useState } from 'react';

import UsasClubFileData from './UsasClubFileData';

const useUsasClubFileData = () => {
  const [usasClubFileUploadState, setUsasClubFileUploadState] = useState(UsasClubFileData.USAS_CLUB_FILE_UPLOAD_INITIAL_STATE);
  const [usasClubFileDownloadState, setUsasClubFileDownloadState] = useState(UsasClubFileData.USAS_CLUB_FILE_DOWNLOAD_INITIAL_STATE);
  const [usasClubFileTemplateState, setUsasClubFileTemplateState] = useState(UsasClubFileData.USAS_CLUB_FILE_TEMPLATE_INITIAL_STATE);

  const postUsasClubFile = (file) => {
    UsasClubFileData.postUsasClubFileData(file, usasClubFileUploadState, setUsasClubFileUploadState);
  };

  const getUsasClubFile = (fileUrl) => {
    return UsasClubFileData.getUsasClubFileData(fileUrl, usasClubFileDownloadState, setUsasClubFileDownloadState);
  };

  const getUsasClubFileTemplate = (type) => {
    return UsasClubFileData.getUsasClubFileTemplateData(type, usasClubFileTemplateState, setUsasClubFileTemplateState);
  };

  const getFileNameFromSavedUploadUrl = () => {
    if (usasClubFileUploadState.isObjLoaded === true) {
      const fileNameWithDateTime = usasClubFileUploadState.objData.uploadUrl.replace(UsasClubFileData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return usasClubFileUploadState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    try {
      const fileNameWithDateTime = url.replace(UsasClubFileData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return decodeURI(fileName) || '';
    } catch (e) {
      return url;
    }
  };

  return {
    usasClubFileUploadState,
    usasClubFileDownloadState,
    usasClubFileTemplateState,
    postUsasClubFile,
    getUsasClubFile,
    getUsasClubFileTemplate,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl
  };
};

export default useUsasClubFileData;