import { API_NAMES, initApi } from "../../utils/HttpApiHelper";
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

const postRecFileData = (file, state, setState) => {
  if (file) {
    const formData = new FormData();
    formData.append("name", file?.name || '');
    formData.append("file", file);

    const api = initApi(API_NAMES.REC, state, setState);
    const url = `/RecFiles`;
    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const getRecFileData = (fileUrl, state, setState) => {
  if (fileUrl) {
    const api = initApi(API_NAMES.REC, state, setState);
    const url = `/RecFiles?fileUrl=${fileUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const BASE_UPLOAD_URL = import.meta.env.VITE_REC_DOCS_UPLOAD_FILES_BASE_UPLOAD_URL;

const REC_FILE_UPLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const REC_FILE_DOWNLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const RecFileData = {
  BASE_UPLOAD_URL,
  REC_FILE_UPLOAD_INITIAL_STATE,
  REC_FILE_DOWNLOAD_INITIAL_STATE,
  postRecFileData,
  getRecFileData
};

export default RecFileData;