import useUsasClubFileInsuranceData from "../../state/clubFileInsurance/UseUsasClubFileInsuranceData";

const useMemberInsuranceInformation = () => {
  const { getUsasClubFileInsuranceTemplate } = useUsasClubFileInsuranceData();

  const onDownloadInformationClicked = (e, type) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    showUploadedFileTemplate(type);
  }

  async function showUploadedFileTemplate(type) {
    if (type) {
      const getFileResult = await getUsasClubFileInsuranceTemplate(type);

      if (getFileResult) {
        if (getFileResult.objData?.blobFile?.size > 0) {
          var file = window.URL.createObjectURL(getFileResult.objData?.blobFile);
          const link = document.createElement('a');

          link.href = file;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    }
  }

  return {
    onDownloadInformationClicked
  };
};

export default useMemberInsuranceInformation;