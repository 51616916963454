import { PreCompHttpHelper, NO_DATA_MESSAGE } from '../../../common/utils/HttpHelper';

//Note: If this file is ever used again, it should be refactored according to task #6097

const PostPreCompFilesInsuranceVerificationData = (file, state, setState) => {
  if (state.isSaving !== true && !state.message) {
    const newState = {
      ...state,
      isSaving: true,
      isSaved: false,
      message: 'Saving...'
    };
    setState(newState);

    const url = `/PreCompetitiveFiles/InsuranceVerification`;
    const formData = new FormData();
    formData.append("name", file?.name || '');
    formData.append("file", file);

    PreCompHttpHelper(url, 'POST', formData, true)
    .then((objData) => {
      if (!objData) {
        throw new Error(NO_DATA_MESSAGE);
      } else {
        setState({
          ...newState,
          isObjLoaded: true,
          isSaving: false,
          isSaved: true,
          objData,
          message: ''
        });
      }
    })
    .catch((e) => {
      const message = e && e.message ? e.message : NO_DATA_MESSAGE;
      setState({
        ...newState,
        isObjLoaded: false,
        isSaving: false,
        isSaved: false,
        message: message
      });
    });
  }
};

export default PostPreCompFilesInsuranceVerificationData;