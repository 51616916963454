import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postPreCompFilesFormW9Data from './PostPreCompFilesFormW9Data';

//Note: If this file is ever used again, it should be refactored according to task #6097

const BASE_UPLOAD_URL = 'https://precompdocs.blob.core.windows.net/uploaded-docs/';
const GET_DOWNLOAD_TEMPLATE_PATH = '/PreCompetitiveFiles/downloadTemplate/';
const GET_PRE_COMP_FILES_FORM_W9_PATH = '/PreCompetitiveFiles/FormW9?fileUrl=';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PreCompFilesFormW9Data = {
  BASE_UPLOAD_URL,
  GET_DOWNLOAD_TEMPLATE_PATH,
  GET_PRE_COMP_FILES_FORM_W9_PATH,
  INITIAL_STATE,
  postPreCompFilesFormW9Data
};

export default PreCompFilesFormW9Data;