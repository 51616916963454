import { Fragment } from 'react';
import Headings from "../../../../common/components/headings/Headings";

import useClubNationalPracticeData from "./UseClubNationalPracticeData";
import SecondaryButton from "../../../../common/components/buttons/SecondaryButton";
import ActionIntraPageButton from "../../../../common/components/buttons/ActionIntraPageButton";
import PopUpModal from "../../../../common/components/dialogs/PopUpModal";
import ViewReport from "../../../../common/components/embeddedReports/ViewReport";
import Constants from '../../../../common/utils/Constants';

import global from '../../../../common/components/GlobalStyle.module.css';

const ClubNationalPracticeData = () => {
  const {
    jsonFileState,
    clubNationalPracticeDataState,
    viewState,
    setViewState,
    reportListState,
    INITIAL_VIEW_STATE,
    onUploadFile,
    FileUpload,
    onRunNPDReport,
    onRunClubRosterReport,
    onDownloadTemplateClicked } = useClubNationalPracticeData();

  const TEMPLATE_TYPE = "NationalPractice"
  const INVALID_FILE_MESSAGE = "This is not a valid National Practice Data file";

  return (
    <Fragment>
      <div className="row">
        <div className="col-xs-12">
          <Headings.H3>National Practice Data</Headings.H3>
        </div>
        <div className="col-xs-12 col-sm-6 usas-extra-top-margin usas-extra-bottom-margin" >
          <ActionIntraPageButton type="button" onClick={(e) => {
            onDownloadTemplateClicked(e, TEMPLATE_TYPE);
          }}>Download Practice Data Template</ActionIntraPageButton>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <ActionIntraPageButton type="button" onClick={() => onRunClubRosterReport()}>Download Roster</ActionIntraPageButton>
        </div>
      </div>
      <div className="row" >
        <div className="col-xs-12" style={{ backgroundColor: 'lightblue', marginLeft: 10, marginBottom: 10, paddingBottom: 5, borderRadius: 10 }}>
          <ul style={{ padding: 20, color: 'darkslateblue' }}>
            <li>Download the National Practice Data Template (Excel spreadsheet) and open in Excel</li>
            <li>Click the Download Roster button to download your team’s roster and copy + paste your member IDs and swimmer names into the Template</li>
            <li>Fill in data and save spreadsheet as a CSV file (Select File - Save As)</li>
            <li>Upload CSV file to SWIMS 3.0 (click 'SELECT A FILE')</li>
            <li>Click 'View Dashboard' to display your Dashboard</li>
          </ul>
        </div>
      </div>
      <div className="row">
        <div className="col-xs-6 col-sm-6">
          <FileUpload />
        </div>
        <div className="col-xs-6 col-sm-6 usas-extra-top-margin">
          <div style={{ float: 'right' }}><ActionIntraPageButton type="button" onClick={() => onRunNPDReport()}>View Dashboard</ActionIntraPageButton>&nbsp;</div>
        </div>
      </div>
      {jsonFileState.practiceDataContent.nationalPracticeData?.length > 0 &&
        <div className="row">
          <div className="col-xs-12">
            <SecondaryButton type="button" onClick={onUploadFile}>Upload Practice Data</SecondaryButton>
            <br />
            <p style={{ fontSize: 22 }}>{clubNationalPracticeDataState.isSaved && clubNationalPracticeDataState.objData.nationalPracticeData.length > 0 &&
              <>Upload Successful! Dashboard will be available in approximately four hours.</>
            }</p>
          </div>
        </div>
      }
      {jsonFileState.isValidFileType === false
        ? <div className="row usas-extra-top-margin">
          <div className="col-xs-12">
            <p className={global.Error} style={{ fontSize: 25 }}>{jsonFileState.isValidFileType === false && INVALID_FILE_MESSAGE}</p>
          </div>
        </div>
        : <Fragment />}
      <PopUpModal
        widthPct={50}
        maxWidth={250}
        title={Constants.LOADING_MSG}
        displayPopUp={reportListState?.isArrayLoading} />

      {viewState.showReport === true &&
        <div className="row">
          <div className="col-xs-12 usas-extra-top-margin">
            <ViewReport
              reportInfoId={viewState.reportInfoId}
              reportParameters={viewState.reportParameters}
              onBackToGrid={() => setViewState({ INITIAL_VIEW_STATE })} />
          </div>
        </div>
      }
    </Fragment >);
};

export default ClubNationalPracticeData;
