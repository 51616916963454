import { API_NAMES, initApi } from '../../../common/utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

const getUsasClubFileInsuranceTemplateData = (type, state, setState) => {
  if (type) {
    const api = initApi(API_NAMES.CLUB, state, setState);
    const url = `/UsasClubFiles/Insurance?type=${type}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const USAS_CLUB_FILE_INSURANCE_TEMPLATE_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const UsasClubFileInsuranceData = {
  USAS_CLUB_FILE_INSURANCE_TEMPLATE_INITIAL_STATE,
  getUsasClubFileInsuranceTemplateData
};

export default UsasClubFileInsuranceData;