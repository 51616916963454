import { BASIC_INITIAL_STATE } from '../../../common/utils/HttpHelper';

import postPreCompFilesEmergencyPlansData from './PostPreCompFilesEmergencyPlansData';

//Note: If this file is ever used again, it should be refactored according to task #6097

const BASE_UPLOAD_URL = 'https://precompdocs.blob.core.windows.net/uploaded-files';
const GET_DOWNLOAD_TEMPLATE_PATH = '/PreCompetitiveFiles/downloadTemplate/';
const GET_PRE_COMP_FILES_EMERGENCY_PLANS_PATH = '/PreCompetitiveFiles/EmergencyPlans?fileUrl=';

const INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const PreCompFilesEmergencyPlansData = {
  BASE_UPLOAD_URL,
  GET_DOWNLOAD_TEMPLATE_PATH,
  GET_PRE_COMP_FILES_EMERGENCY_PLANS_PATH,
  INITIAL_STATE,
  postPreCompFilesEmergencyPlansData
};

export default PreCompFilesEmergencyPlansData;