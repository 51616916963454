import { useEffect, useState } from "react";

import validate from "./MeetSanctionInfoFormValidation";

import useBaseUsasClubData from "../../../../../../common/state/clubBase/UseBaseUsasClubData";
import useMeetFileData from "../../../../../../common/state/meetFile/UseMeetFileData";

import Constants from "../../../../../../common/utils/Constants";
import UseForm from "../../../../../../common/utils/UseForm";

const SAVING_ERROR_MSG = 'The last action is still saving. Please try again.';
const FILE_TYPE_ERROR_MSG = 'File type not permitted. Please provide a (*.pdf), (*.doc), or (*.docx) file.';

const UseMeetSanctionInfoForm = (savedFormState, onSubmitFormCallback, state, setState) => {
  const { formState, errorState, setIsDirty, handleSubmit, updateFormState, onValueTextPairChanged, setFormState
  } = UseForm(getInitialFormState, onSubmitFormCallback, validate);
  const { baseUsasClubState } = useBaseUsasClubData();
  const [removeFileModalState, setRemoveFileModalState] = useState(getInitialRemoveFileModalState());
  const { meetFileUploadState, postMeetFile, getMeetFile, getFileNameFromSavedUploadUrl } = useMeetFileData();

  const onOpenRemoveFileModalClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...removeFileModalState, fileName: formState.meetAnnouncementFileName, displayPopUp: true });
  }

  const onRemoveFileModalCanceled = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }
    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
  }

  const onUploadTemplateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    if (meetFileUploadState.isObjLoading === true) {
      setState({ ...state, error: SAVING_ERROR_MSG });
    }
    else {
      setState({ ...state, error: '' });
      if (e.target.files[0]) {
        const file = e.target.files[0];
        const fileType = e.target.files[0].type;
        if (fileType === Constants.FILE_TYPE_PDF || fileType === Constants.FILE_TYPE_DOC ||
          fileType === Constants.FILE_TYPE_DOCX) {
          postMeetFile(file);
          setState({ ...state, error: '' });
        }
        else {
          setState({ ...state, error: FILE_TYPE_ERROR_MSG });
        }
      }
    }
  }

  const onRemoveTemplateClicked = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    setRemoveFileModalState({ ...getInitialRemoveFileModalState() });
    if (meetFileUploadState.isSaving === true) {
      setState({ ...state, error: SAVING_ERROR_MSG });
    }
    else {
      setState({ ...state, error: '' });
      setFormState({ ...formState, meetAnnouncementUrl: '', meetAnnouncementFileName: '' });
    }
  }

  const onDownloadUploadedFile = (e) => {
    if (e && e.preventDefault) {
      e.preventDefault();
    }

    showUploadedFile(formState.meetAnnouncementUrl);
  }

  useEffect(() => {
    setFormState({
      ...formState,
      ...savedFormState,
      autogeneratedMeetLsc: baseUsasClubState?.objData?.orgUnit?.parentOrgUnitCode || ''
    });
    setIsDirty(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (meetFileUploadState.isObjLoaded === true && Object.keys(meetFileUploadState.objData).length > 0) {
      setFormState({ ...formState, meetAnnouncementUrl: meetFileUploadState.objData.uploadUrl, meetAnnouncementFileName: getFileNameFromSavedUploadUrl() });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meetFileUploadState])

  async function showUploadedFile(fileUrl) {
    if (fileUrl) {
      const getFileResult = await getMeetFile(fileUrl);

      if (getFileResult) {
        if (getFileResult.objData?.blobFile?.size > 0) {
          var file = window.URL.createObjectURL(getFileResult.objData?.blobFile);
          const link = document.createElement('a');

          link.href = file;
          link.setAttribute('target', '_blank');
          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        }
      }
    }
  }

  function getInitialFormState() {
    return {
      meetName: '',
      startDate: Constants.BLANK_DATE_STRING,
      endDate: Constants.BLANK_DATE_STRING,
      meetClassification: [],
      meetCourse: [],
      meetSoftwareId: Constants.DEFAULT_ID,
      meetSoftwareName: '',
      facilityId: Constants.DEFAULT_ID,
      facilityName: '',
      autogeneratedMeetYear: '',
      autogeneratedMeetLsc: '',
      meetAnnouncementUrl: '',
      meetAnnouncementFileName: ''
    };
  }

  function getInitialRemoveFileModalState() {
    return {
      displayPopUp: false,
      fileName: '',
      modalTitle: 'Remove Uploaded File?'
    }
  }

  return {
    formState,
    errorState,
    handleSubmit,
    onFormValueChanged: updateFormState,
    onValueTextPairChanged,
    onUploadTemplateClicked,
    onOpenRemoveFileModalClicked,
    onRemoveFileModalCanceled,
    onRemoveTemplateClicked,
    meetFileUploadState,
    removeFileModalState,
    onDownloadUploadedFile
  };
};

export default UseMeetSanctionInfoForm;