const createOrgObjFromInsuranceForm = (orgObj, formState, insuranceValidationUrl, providerIndex, policyIndex, policyTypeId) => {
  const isContextOrg = orgObj.preCompetitiveOrganizationId === undefined;

  if (providerIndex >= 0) {
    const targetProvider = orgObj.insuranceProvider[providerIndex];

    if (targetProvider) {
      targetProvider.insuranceProviderName = formState.insuranceProviderName.trim();
      targetProvider.isSelfInsured = formState.isSelfInsured;

      if (policyIndex >= 0) {
        const targetPolicy = targetProvider.insuranceProviderPolicy[policyIndex];

        // Edit Policy
        if (targetPolicy) {
          targetPolicy.policyNumber = formState.policyNumber.trim();
          targetPolicy.policyTypeId = policyTypeId;
          targetPolicy.effectiveDate = formState.effectiveDate;
          targetPolicy.expirationDate = formState.expirationDate;
          targetPolicy.occurrenceLimit = parseInt(formState.occurrenceLimit);
          targetPolicy.aggregateLimit = parseInt(formState.aggregateLimit);
          targetPolicy.insuranceValidationUrl = insuranceValidationUrl;
          targetPolicy.policyType = undefined;
        }
      }
      // Add Policy
      else {
        targetProvider.insuranceProviderPolicy.push({
          insuranceProviderId: targetProvider.insuranceProviderId,
          insuranceProviderPolicyId: isContextOrg
            ? createUniqueId(formState.policyNumber.trim().replace(/\s/g, ''))
            : undefined,
          policyNumber: formState.policyNumber.trim(),
          policyTypeId: policyTypeId,
          effectiveDate: formState.effectiveDate,
          expirationDate: formState.expirationDate,
          occurrenceLimit: parseInt(formState.occurrenceLimit),
          aggregateLimit: parseInt(formState.aggregateLimit),
          insuranceValidationUrl: insuranceValidationUrl,
          policyType: undefined
        });
      }
    }

  }
  // Add Provider & Policy
  else {
    const insuranceProviderId = isContextOrg
      ? createUniqueId(formState.insuranceProviderName.replace(/\s/g, ''))
      : undefined;

    const newProvider = {
      insuranceProviderId,
      insuranceProviderName: formState.insuranceProviderName.trim(),
      isSelfInsured: formState.isSelfInsured,
      insuranceProviderPolicy: [{
        insuranceProviderId,
        insuranceProviderPolicyId: isContextOrg
          ? createUniqueId(formState.policyNumber.trim().replace(/\s/g, ''))
          : undefined,
        policyNumber: formState.policyNumber.trim(),
        policyTypeId: policyTypeId,
        effectiveDate: formState.effectiveDate,
        expirationDate: formState.expirationDate,
        occurrenceLimit: parseInt(formState.occurrenceLimit),
        aggregateLimit: parseInt(formState.aggregateLimit),
        insuranceValidationUrl: insuranceValidationUrl,
        policyType: undefined
      }],
      preCompetitiveOrganizationId: orgObj.preCompetitiveOrganizationId,
    };

    orgObj.insuranceProvider.push(newProvider);
  }

  return orgObj;
};

const createOrgObjFromOrgInfoForm = (orgObj, formState, isBasicInfo, isSpecialInfo, formW9Url) => {
  let basicInfo = {};
  let specialInfo = {};

  if (isBasicInfo === true) {
    basicInfo = {
      federalTaxId: formState.federalTaxId.trim(),
      website: formState.website.trim(),
      tradeName: formState.tradeName.trim(),
      businessEntityTypeId: formState.businessEntityTypeId,
      businessEntityType: undefined,
      address: {
        ...orgObj.address,
        address1: formState.address1.trim(),
        address2: formState.address2.trim(),
        city: formState.city.trim(),
        stateCode: formState.stateCode,
        postalCode: formState.postalCode.trim()
      },
      preCompOrganizationAffiliation: formState.preCompOrganizationAffiliation.map(x => {
        return {
          preCompetitiveAffiliationTypeId: x.id,
          preCompetitiveOrganizationId: orgObj.preCompetitiveOrganizationId > 0 ? orgObj.preCompetitiveOrganizationId : undefined
        };
      })
    };
  }

  if (isSpecialInfo === true) {
    specialInfo = {
      federalTaxId: formState.federalTaxId.trim(),
      orgUnit: {
        ...orgObj.orgUnit,
        orgUnitName: formState.orgUnitName
      }
    };
  }

  if (formW9Url) {
    basicInfo.formW9Url = formW9Url;
  }

  return {
    ...orgObj,
    ...basicInfo,
    ...specialInfo
  };
};

const createLocObjFromLocInfoForm = (locObj, formState) => {
  return {
    ...locObj,
    locationName: formState.locationName.trim(),
    averageLessonCost: parseFloat(formState.averageLessonCost),
    averageSessionCost: parseFloat(formState.averageSessionCost),
    website: formState.website.trim(),
    financialAidOffered: formState.financialAidOffered,
    instructorStudentRatioId: formState.instructorStudentRatioId,
    instructorStudentRatio: undefined,
    preCompCurriculumId: formState.preCompCurriculumId,
    preCompCurriculum: undefined,
    instructionHoursPerSessionId: formState.instructionHoursPerSessionId,
    instructionHoursPerSession: undefined,
    orgUnit: {
      ...locObj.orgUnit,
      orgUnitName: formState.locationName.trim()
    }
  };
};

const createLocObjFromLocInstructorTrainingForm = (locObj, formState) => {
  return {
    ...locObj,
    preEmploymentScreening: formState.preEmploymentScreening,
    protectionPolicy: formState.protectionPolicy,
    backgroundCheckRequired: formState.backgroundCheckRequired,
    instructorTrainingAgencyId: formState.instructorTrainingAgencyId,
    instructorTrainingAgency: undefined,
    inServiceInstructionHoursId: formState.inServiceInstructionHoursId,
    inServiceInstructionHours: undefined,
    inClassroomInstructionHoursId: formState.inClassroomInstructionHoursId,
    inClassroomInstructionHours: undefined,
    inWaterInstructionHoursId: formState.inWaterInstructionHoursId,
    inWaterInstructionHours: undefined,
    inShadowInstructionHoursId: formState.inShadowInstructionHoursId,
    inShadowInstructionHours: undefined,
    inPracticeInstructionHoursId: formState.inPracticeInstructionHoursId,
    inPracticeInstructionHours: undefined
  };
};

const createLocObjFromLocFacilityForm = (locObj, formState, targetIndex, facilityId, facility) => {
  // Edit
  if (targetIndex >= 0) {
    const targetOrgUnitFacility = locObj.orgUnit.orgUnitFacility[targetIndex];
    targetOrgUnitFacility.facility.contactName = formState.contactName.trim();
    targetOrgUnitFacility.facility.contactEmailAddress = formState.contactEmailAddress.trim();
    targetOrgUnitFacility.facility.contactPhoneNumber = formState.contactPhoneNumber;
    targetOrgUnitFacility.clubFacilityContactName = formState.clubFacilityContactName.trim();
    targetOrgUnitFacility.clubFacilityContactEmailAddress = formState.clubFacilityContactEmailAddress.trim();
    targetOrgUnitFacility.clubFacilityContactPhoneNumber = formState.clubFacilityContactPhoneNumber;
  }
  // Add
  else {
    locObj.orgUnit.orgUnitFacility.push({
      clubFacilityContactName: formState?.clubFacilityContactName?.trim() || null,
      clubFacilityContactEmailAddress: formState?.clubFacilityContactEmailAddress?.trim() || null,
      clubFacilityContactPhoneNumber: formState?.clubFacilityContactPhoneNumber || null,
      orgUnitId: locObj.orgUnitId,
      facilityId,
      facility
    });
  }

  return locObj;
};

const createUniqueId = (name) => {
  return `!${name}${Math.round(Math.random() * 1000000)}`;
};

const createShallowPerson = (personData, personOrgRole) => {
  return {
    personId: personData.personId !== '' ? personData.personId : createUniqueId(personData.firstName),
    firstName: personData.firstName || '',
    lastName: personData.lastName || '',
    email: personData.email || '',
    phoneNumber: personData.phoneNumber || '',
    birthDate: personData.birthDate || '',
    personOrgRole
  };
};

const createLocObjFromLocProgramOfferingForm = (locObj, formState) => {
  const isContextLocation = locObj.preCompetitiveLocationId === undefined;

  if (formState.preCompLocationOfferingId) { //edit
    const targetOffering = locObj.preCompLocationOffering.find(x => x.preCompLocationOfferingId === formState.preCompLocationOfferingId);

    if (targetOffering) {
      targetOffering.enrollmentCount = parseInt(formState.enrollmentCount);
      targetOffering.scholarshipCount = parseInt(formState.scholarshipCount);
    }
  } else { // add
    locObj.preCompLocationOffering.push({
      preCompLocationOfferingId: isContextLocation === true ? createUniqueId(`${formState.programOffering[0].name}`) : undefined,
      preCompOfferingType: isContextLocation === true ? { typeName: formState.programOffering[0].name } : undefined,
      preCompOfferingTypeId: formState.programOffering[0].id,
      enrollmentCount: parseInt(formState.enrollmentCount),
      scholarshipCount: parseInt(formState.scholarshipCount),
      preCompetitiveLocationId: locObj.preCompetitiveLocationId || undefined
    });
  }

  return locObj;
};

const isValidLink = (link) => {
  try {
    const trimmedLink = link.trim();
    const http = trimmedLink.substring(0, 7);

    if (http === 'http://' && link.length > 7) {
      return true;
    } else {
      const https = trimmedLink.substring(0, 8);

      if (https === 'https://' && link.length > 8) {
        return true;
      } else {
        return false;
      }
    }
  } catch {
    return false;
  }
};

const getUploadedFileName = (fileUrl) => {
  try {
    const splitStringArray = fileUrl.split('/');
    const indexOfEndOfDateTime = splitStringArray[splitStringArray.length - 1].indexOf('.') + 1;
    const fileName = splitStringArray[splitStringArray.length - 1].substring(indexOfEndOfDateTime);
    return decodeURI(fileName);
  } catch {
    return fileUrl;
  }
};

export {
  createOrgObjFromInsuranceForm, createOrgObjFromOrgInfoForm, createUniqueId, createLocObjFromLocInfoForm,
  createLocObjFromLocInstructorTrainingForm, createLocObjFromLocFacilityForm, isValidLink, createShallowPerson,
  createLocObjFromLocProgramOfferingForm, getUploadedFileName
};