import { useState } from 'react';

import validate from './SearchMemberWithLscPopupValidation'

import useMemberLookupData from '../../../state/memberLookup/UseMemberLookupData';
import useEnvironmentVariableData from '../../../state/environmentVariable/UseEnvironmentVariableData';

import UseForm from '../../../utils/UseForm';
import { DEFAULT_ID } from '../../../utils/Constants';

const INITIAL_STATE = {
  displayPopup: false
};

const INITIAL_FORM_STATE = {
  firstName: '',
  lastName: '',
  orgUnitId: '',
  orgUnitName: ''
};

const useSearchMemberWithLscPopup = (
  viewFormState,
  setViewFormData,
  memberIdName,
  roleGroupName,
  isCurrentOrgUnitMember,
  orgUnitId
) => {

  const { USAS_ORG_UNIT_ID } = useEnvironmentVariableData();
  const { memberLookupState, getMemberLookupByOrgUnit } = useMemberLookupData();
  const { formState, errorState, updateFormState, onValueTextPairChanged, handleSubmit
  } = UseForm(INITIAL_FORM_STATE, onSubmitFormCallback, validate);
  const [state, setState] = useState(INITIAL_STATE);

  const onDisplayPopupClicked = () => {
    setState({ ...state, displayPopup: true });
  };

  const onHidePopupClicked = () => {
    setState({ ...state, displayPopup: false });
  };

  const onSelectPerson = (e, memberId) => {
    e?.preventDefault();

    setViewFormData({ ...viewFormState, [memberIdName]: memberId }, true);
    setState({ ...state, displayPopup: false });
  };

  function onSubmitFormCallback(formState) {
    let orgUnitIdForParam = DEFAULT_ID;

    if (formState.orgUnitId !== '' && formState.orgUnitId !== DEFAULT_ID) {
      orgUnitIdForParam = formState.orgUnitId;
    } else if (orgUnitId !== undefined) {
      orgUnitIdForParam = orgUnitId;
    }

    getMemberLookupByOrgUnit(formState.firstName, formState.lastName, orgUnitIdForParam, roleGroupName, isCurrentOrgUnitMember);
  }

  return {
    state,
    memberLookupState,
    popupFormState: formState,
    popupErrorState: errorState,
    updatePopupFormState: updateFormState,
    updatePopupFormStateValuePair: onValueTextPairChanged,
    handleSubmit,
    onDisplayPopupClicked,
    onHidePopupClicked,
    onSelectPerson
  };
};

export default useSearchMemberWithLscPopup;