import { Fragment } from 'react';

import useMemberInsuranceInformation from './UseMemberInsuranceInformation';

import Headings from '../../../common/components/headings/Headings';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';

const INSURANCE = 'insurance';

const MemberInsuranceInformation = () => {
  const { onDownloadInformationClicked } = useMemberInsuranceInformation();

  return (
    <Fragment>
      <div className='row'>
        <div className='col-xs-12'>
          <Headings.H3>Member Insurance Information</Headings.H3>
        </div>
        <div className='col-xs-12 usas-extra-top-margin'>
          <PrimaryButton onClick={(e) => onDownloadInformationClicked(e, INSURANCE)}>Download Information</PrimaryButton>
        </div>
      </div>
    </Fragment>
  );
};

export default MemberInsuranceInformation;