import { API_NAMES, initApi } from '../../utils/HttpApiHelper';
import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

const postMeetFileData = (file, state, setState) => {
  if (file) {
    const formData = new FormData();
    formData.append("name", file?.name || '');
    formData.append("file", file);

    const api = initApi(API_NAMES.MEET, state, setState);
    const url = `/MeetFiles/Announcements`;
    return api?.executeObject ? api.executeObject(url, 'POST', formData, true) : null;
  }
};

const getMeetFileData = (fileUrl, state, setState) => {
  if (fileUrl) {
    const api = initApi(API_NAMES.MEET, state, setState);
    const url = `/MeetFiles/Announcements?fileUrl=${fileUrl}`;

    return api?.executeObject ? api.executeObject(url, 'GET', null, false, true) : null;
  }
};

const BASE_UPLOAD_URL = import.meta.env.VITE_MEET_DOCS_ANNOUNCEMENTS_BASE_UPLOAD_URL;

const MEET_FILE_UPLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MEET_FILE_DOWNLOAD_INITIAL_STATE = {
  ...BASIC_INITIAL_STATE
};

const MeetFileData = {
  BASE_UPLOAD_URL,
  MEET_FILE_UPLOAD_INITIAL_STATE,
  MEET_FILE_DOWNLOAD_INITIAL_STATE,
  postMeetFileData,
  getMeetFileData
};

export default MeetFileData;