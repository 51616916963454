import { useState } from 'react';

import UsasClubFileInsuranceData from './UsasClubFileInsuranceData';

const useUsasClubFileInsuranceData = () => {
  const [usasClubFileInsuranceTemplateState, setUsasClubFileInsuranceTemplateState] = useState(UsasClubFileInsuranceData.USAS_CLUB_FILE_INSURANCE_TEMPLATE_INITIAL_STATE);

  const getUsasClubFileInsuranceTemplate = (type) => {
    return UsasClubFileInsuranceData.getUsasClubFileInsuranceTemplateData(type, usasClubFileInsuranceTemplateState, setUsasClubFileInsuranceTemplateState);
  };

  return {
    usasClubFileInsuranceTemplateState,
    getUsasClubFileInsuranceTemplate
  };
};

export default useUsasClubFileInsuranceData;