import { useState } from 'react';

import MeetFileData from './MeetFileData';

const useMeetFileData = () => {
  const [meetFileUploadState, setMeetFileUploadState] = useState(MeetFileData.MEET_FILE_UPLOAD_INITIAL_STATE);
  const [meetFileDownloadState, setMeetFileDownloadState] = useState(MeetFileData.MEET_FILE_DOWNLOAD_INITIAL_STATE);

  const postMeetFile = (file) => {
    MeetFileData.postMeetFileData(file, meetFileUploadState, setMeetFileUploadState);
  };

  const getMeetFile = (fileUrl) => {
    return MeetFileData.getMeetFileData(fileUrl, meetFileDownloadState, setMeetFileDownloadState);
  };

  const getFileNameFromSavedUploadUrl = () => {
    if (meetFileUploadState.isObjLoaded === true) {
      const fileNameWithDateTime = meetFileUploadState.objData.uploadUrl.replace(MeetFileData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return meetFileUploadState.objData.uploadUrl ? decodeURI(fileName) : '';
    } else {
      return '';
    }
  };

  const getFileNameFromUrl = (url) => {
    try {
      const fileNameWithDateTime = url.replace(MeetFileData.BASE_UPLOAD_URL, '');
      const indexOfEndOfDateTime = fileNameWithDateTime.indexOf('.') + 1;
      const fileName = fileNameWithDateTime.substring(indexOfEndOfDateTime);
      return decodeURI(fileName) || '';
    } catch (e) {
      return url;
    }
  };

  return {
    meetFileUploadState,
    meetFileDownloadState,
    postMeetFile,
    getMeetFile,
    getFileNameFromSavedUploadUrl,
    getFileNameFromUrl
  };
};

export default useMeetFileData;